import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import axios from "axios";
import "../App.css";
import { useAuth } from "../AuthContext"; // Import your AuthContext
import { toast, ToastContainer } from "react-toastify";

const Event = React.lazy(() => import("../pages/Event"));
const Device = React.lazy(() => import("../pages/Device"));
const EventsPage = React.lazy(() => import("../pages/EventsPage"));
const NewEvent = React.lazy(() => import("../pages/NewEvent"));
const DevicesPage = React.lazy(() => import("../pages/DevicesPage"));
const ArchivedEvents = React.lazy(() => import("../pages/ArchivedEvents"));
const ArchivedDevices = React.lazy(() => import("../pages/ArchivedDevices"));

function HomeRoutes() {
  const [eventsData, setEventsData] = useState(null);
  const [charityData, setCharityData] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  function ProtectedRoute({ element, ...rest }) {
    return user ? React.cloneElement(element, rest) : <Navigate to="/login" />;
  }

  useEffect(() => {
    const apiUrl = `https://${process.env.REACT_APP_SERVER_DOMAIN}${process.env.REACT_APP_BACKEND_PATH}/getEvents.php`;

    const params = {
      username: user.username,
      password: user.password,
    };
    // Define a function to fetch events data
    const fetchEventsData = async () => {
      try {
        // Your fetch logic here
        const response = await axios.get(apiUrl, { params });
        // console.log(response.data);
        if (response.data!="") setEventsData(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    // Call fetchEventsData immediately when the component mounts
    fetchEventsData();

  }, []);

  return (
    <Routes>
      <Route
        path="/events"
        element={
          <ProtectedRoute
            element={<EventsPage eventsData={eventsData} user={user} />}
          />
        }
        component={EventsPage}
        user={user}
      />
      <Route
        path="devices"
        element={<ProtectedRoute element={<DevicesPage user={user} />} />}
        component={DevicesPage}
        user={user}
      />
      <Route
        path={`/events/:eventID`}
        element={<ProtectedRoute element={<Event user={user} />} />}
        component={Event}
        user={user}
      />
      <Route
        path={`/events/newEvent`}
        element={<ProtectedRoute element={<NewEvent user={user} />} />}
        component={NewEvent}
        user={user}
      />
      <Route
        path="devices/device"
        element={<ProtectedRoute element={<Device user={user} />} />}
        component={Device}
        user={user}
      />
      <Route
        path="archivedEvents"
        element={<ProtectedRoute element={<ArchivedEvents user={user} />} />}
        component={ArchivedEvents}
        user={user}
      />
      <Route
        path="archivedDevices"
        element={<ProtectedRoute element={<ArchivedDevices user={user} />} />}
        component={ArchivedDevices}
        user={user}
      />
    </Routes>
  );
}

export default HomeRoutes;
