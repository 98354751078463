import React, { useEffect, useState } from "react";
import HomeRoutes from "../routes/Routes";
import Breadcrumb from "../components/BreadCrumb";
import Logout from "../components/Logout";

function Home({ user }) {
  return (
    <>
      <Logout user={user}/>
      {/* <Carousel /> */}
      {/* <Drawer> */}
      <Breadcrumb user={user}/>

      <HomeRoutes user={user} />
      {/* </Drawer> */}
    </>
  );
}

export default Home;
