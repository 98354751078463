import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

// export const getUser = () => {
//   const storedUser = localStorage.getItem("user");

//   return storedUser;
// };

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem("user"));

  const login = (response, username, password) => {
    // Simulate successful authentication (replace with your own logic)
    if (response?.data) {
      localStorage.setItem("user", JSON.stringify({ username: username, password: password, charity_id:  response.data.charity_id, charity:  response.data.charity, charity_active:  response.data.charity_active, active: response.data.active}));
      const storedUser = localStorage.getItem("user");
      // console.log("userrrr : " + storedUser);
      setUser(storedUser);
    } else {
      // console.log(username, "username", response, "name");
      throw new Error("Invalid credentials");
    }
  };
  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
  };

  function setupInactivityLogout() {
    let inactivityTimer;

    function startInactivityTimer() {
      inactivityTimer = setTimeout(() => {
        logout();
      }, 1 * 60 * 60 * 1000); // 4 hours in milliseconds
    }

    function resetInactivityTimer() {
      clearTimeout(inactivityTimer);
      startInactivityTimer();
    }

    // Add event listeners to detect user activity.
    document.addEventListener("click", resetInactivityTimer);
    document.addEventListener("mousemove", resetInactivityTimer);
    document.addEventListener("keydown", resetInactivityTimer);

    const logout = () => {
      setUser(null);
      localStorage.removeItem("user");
    };

    // Call startInactivityTimer when the user logs in or interacts with your app.
    startInactivityTimer();
  }

  // Call setupInactivityLogout when your application initializes.
  setupInactivityLogout();

  useEffect(() => {
    const storedUser = localStorage.getItem("user");

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
