import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import LogoutIcon from "@mui/icons-material/Logout";
// import zakahImage from "../assets/zakahImage.jpg";
import logoImage from "../assets/logo_w.png";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../AuthContext"; // Import useAuth

export default function Menu({ user }) {
  const [value, setValue] = useState(0);
  const { logout } = useAuth(); // Use the login function from AuthContext

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        margin: "0",
        width: "100%",
        background: `linear-gradient(45deg, ${process.env.REACT_APP_COLOR1}, ${process.env.REACT_APP_COLOR2})`,
        position: "relative", // Add position relative to the parent
      }}
    >
      <Tabs
        value={value}
        // onChange={handleChange}
        aria-label="Tabs where selection follows focus"
        selectionFollowsFocus
        textColor="inherit"
        indicatorColor="none"
        sx={{
          height: "80px",
          zIndex: 1,
          marginBottom: "5px",
        }} // Ensure Tabs are on top
        dir="rtl"
      >
        <Tab
          label="تسجيل الخروج"
          onClick={logout}
          iconPosition="start"
          sx={{ color: "#ffff", marginBottom: "5px", fontFamily: "Noto Kufi Arabic", fontWeight: "bold" }}
        />

        <Tab
          label={user.charity}
          // onClick={logout}
          iconPosition="start"
          sx={{ color: "#ffff", marginBottom: "5px", fontFamily: "Noto Kufi Arabic", fontWeight: "bold" }}
        />

      </Tabs>
      <img
        src={logoImage}
        alt="Zakah"
        width={"15%"}
        // height={"200px"}
        style={{
          position: "absolute", // Position the image absolutely
          objectFit: "contain",
          backgroundColor: "white",
          padding: "0 0 20px 0",
          top: "0", // Adjust the top position as needed
          left: "8%", // Adjust the right position as needed
          zIndex: 2, // Ensure the image is on top
          borderRadius: "0 0 30px 30px",
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
        }}
      />
    </Box>
  );
}
