import React, { useEffect, useState } from "react";
import Axios from "axios";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useLocation } from "react-router-dom";

const translate = (segment) => {
  switch (segment) {
    case "events":
      return "الحملات";
    case "edit":
      return "تعديل";
    case "devices":
      return "الأجهزة";
    case "newEvent":
      return "حملة جديدة";
    // Add translations for other segments as needed
    default:
      return segment;
  }
};

function isNumeric(str) {
  return /^\d+$/.test(str);
}

function EventSegment({ eventID, user }) {
  const [eventData, setEventData] = useState(null);
  useEffect(() => {

    const formData = new FormData();
    formData.append("username", user.username);
    formData.append("password", user.password);
    formData.append("event_id", eventID);

    const apiUrl = `https://${process.env.REACT_APP_SERVER_DOMAIN}${process.env.REACT_APP_BACKEND_PATH}/getEvents.php`;

    const fetchData = async () => {
      try {
        const response = await Axios.post(apiUrl, formData);
        setEventData(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [eventID]);

  return (
    <Typography color={process.env.REACT_APP_COLOR3} sx={{ fontWeight: "bold", fontFamily: "Noto Kufi Arabic" }}>
      {eventData?.name || `...تحميل`}
    </Typography>
  );
}

export default function CustomBreadcrumbs({user}) {
  const location = useLocation();
  const pathSegments = location.pathname.split("/").filter(Boolean);

  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon sx={{ transform: "scaleX(-1)" }} fontSize="small" />
      }
      aria-label="breadcrumb"
      sx={{
        textTransform: "uppercase",
        fontSize: "12px",
        fontFamily: "Noto Kufi Arabic",
        color: "grey",
        padding: "8px 16px",
        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
        backgroundColor: "white",
        textAlign: "right",
        direction: "rtl",
        display: "flex", // Ensure items are in the same row
      }}
    >
      {pathSegments.map((segment, index) => (
        <span key={segment}>
          {isNumeric(segment) ? (
            <EventSegment eventID={segment} user={user} />
          ) : (
            <>
              <Link
                href={`/${pathSegments.slice(0, index + 1).join("/")}`}
                sx={{
                  fontSize: "15px",
                  textDecoration: "none",
                  color: "grey",
                  "&:hover": { color: "grey", textDecoration: "underline" },
                }}
              >
                {translate(segment)}
              </Link>
              {/* {index < pathSegments.length - 1 && (
                <NavigateNextIcon
                  sx={{ transform: "scaleX(-1)" }}
                  fontSize="small"
                />
              )} */}
            </>
          )}
        </span>
      ))}
    </Breadcrumbs>
  );
}
